/**
 * @name RSS Feed Management System
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description RSS Feed Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */

import {
  Button, Form, Input, Select
} from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { reFetchData } from '../../store/slice/appSlice';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function CreateRss() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // function to handle create new RSS feed
  const onFinish = (values) => {
    const formdata = {
      title: values.title,
      url: values.url,
      type: values.type,
      description: values.description
    };

    setLoading(true);
    ApiService.post('/rss/add', formdata)
      .then((response) => {
        setLoading(false);
        if (response?.result_code === 0) {
          notificationWithIcon('success', 'SUCCESS', response?.result?.message || 'New RSS feed created successfully');
          form.resetFields();
          dispatch(reFetchData());
        } else {
          notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
      });
  };

  return (
    <Form
      form={form}
      className='login-form'
      name='create-new-rss-form'
      onFinish={onFinish}
      layout='vertical'
    >
      <Form.Item
        label='Title'
        name='title'
        rules={[{
          required: true,
          message: 'Please input the RSS feed title!'
        }]}
      >
        <Input
          placeholder='RSS Feed Title'
          size='large'
          type='text'
          allowClear
        />
      </Form.Item>

      <Form.Item
        label='URL'
        name='url'
        rules={[{
          required: true,
          message: 'Please input the RSS feed URL!',
          type: 'url'
        }]}
      >
        <Input
          placeholder='RSS Feed URL'
          size='large'
          type='text'
          allowClear
        />
      </Form.Item>

      <Form.Item
        label='Type'
        name='type'
        rules={[{
          required: true,
          message: 'Please select the RSS feed type!'
        }]}
      >
        <Select
          placeholder='-- select RSS feed type --'
          optionFilterProp='children'
          options={[
            { value: 'song', label: 'Song' },
            { value: 'podcast', label: 'Podcast' }
          ]}
          size='large'
          allowClear
        />
      </Form.Item>

      {/* <Form.Item
        label='Description'
        name='description'
        rules={[{
          required: true,
          message: 'Please input the RSS feed description!'
        }]}
      >
        <Input.TextArea
          placeholder='Type here RSS feed description'
          rows={4}
        />
      </Form.Item> */}

      <Form.Item>
        <Button
          className='login-form-button mt-4'
          htmlType='submit'
          type='primary'
          size='large'
          loading={loading}
          disabled={loading}
        >
          Create New RSS Feed
        </Button>
      </Form.Item>
    </Form>
  );
}

export default React.memo(CreateRss);
