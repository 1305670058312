/**
 * @name Radio EMIA
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
const antTheme = {
  colorPrimary: '#2563eb',
  colorSuccess: '#45d175',
  colorWarning: '#f59e0b',
  colorError: '#ff0000',
  colorInfo: '#5189f5',
  colorBgBase: '#ffffff',
  borderRadius: 6
};

export default antTheme;
