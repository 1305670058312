/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import { Button, Input, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function SongModal({ statusUpdateModal, setStatusUpdateModal, setFetchAgain }) {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState('');
  const [album, setAlbum] = useState('');
  const [duration, setDuration] = useState('');
  const [audioUrl, setAudioUrl] = useState('');

  // Utiliser useEffect pour préremplir les champs si la chanson existe (pour la mise à jour)
  useEffect(() => {
    if (statusUpdateModal?.song) {
      const {
        title, artist, album, duration, audioUrl
      } = statusUpdateModal.song;
      setTitle(title);
      setArtist(artist);
      setAlbum(album);
      setDuration(duration);
      setAudioUrl(audioUrl);
    } else {
      setTitle('');
      setArtist('');
      setAlbum('');
      setDuration('');
      setAudioUrl('');
    }
  }, [statusUpdateModal]);

  const handleSubmit = () => {
    setLoading(true);
    const songData = {
      title, artist, album, duration, audioUrl
    };

    const apiCall = statusUpdateModal?.song ?
      ApiService.put(`/song/${statusUpdateModal.song._id}`, songData) :
      ApiService.post('/song/add', songData);

    apiCall
      .then((res) => {
        setLoading(false);
        if (res?.result_code === 0) {
          notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Operation successful');
          setStatusUpdateModal((prevState) => ({ ...prevState, open: false, song: null }));
          setFetchAgain((prevState) => !prevState);
        } else {
          notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
        }
      })
      .catch((err) => {
        setLoading(false);
        notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || 'Sorry! Something went wrong. App server error');
      });
  };

  return (
    <Modal
      title={statusUpdateModal?.song ? 'Update Song Info' : 'Create Song'}
      visible={statusUpdateModal?.open}
      onOk={() => setStatusUpdateModal((prevState) => ({ ...prevState, open: false, song: null }))}
      onCancel={() => setStatusUpdateModal((prevState) => ({ ...prevState, open: false, song: null }))}
      footer={[
        <Button onClick={() => setStatusUpdateModal((prevState) => ({ ...prevState, open: false, song: null }))} key='back'>
          Cancel
        </Button>,
        <Button onClick={handleSubmit} type='primary' key='submit' disabled={loading} loading={loading}>
          {statusUpdateModal?.song ? 'Update' : 'Create'}
        </Button>
      ]}
    >
      <Input className='w-full my-2' placeholder='Enter song title' value={title} onChange={(e) => setTitle(e.target.value)} />
      <Input className='w-full my-2' placeholder='Enter artist ID' value={artist} onChange={(e) => setArtist(e.target.value)} />
      <Input className='w-full my-2' placeholder='Enter album ID' value={album} onChange={(e) => setAlbum(e.target.value)} />
      <Input className='w-full my-2' placeholder='Enter duration in seconds' value={duration} onChange={(e) => setDuration(e.target.value)} />
      <Input className='w-full my-2' placeholder='Enter audio URL' value={audioUrl} onChange={(e) => setAudioUrl(e.target.value)} />
    </Modal>
  );
}

export default SongModal;
