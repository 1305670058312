/**
 * @name Songs List
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
import {
  Empty, Pagination, Result, Skeleton
} from 'antd';
import React, { useEffect, useState } from 'react';
import { v4 as uniqueId } from 'uuid';
import useFetchData from '../../hooks/useFetchData';
import QueryOptions from '../shared/QueryOptions';
import { formatDate, formatSeconds } from '../../utils/format';
import SongModal from './SongModal';

function SongsList({ refetch }) {
  const [fetchAgain, setFetchAgain] = useState(false);
  const [query, setQuery] = useState({
    search: '', sort: 'asc', page: '1', rows: '10'
  });
  const [statusUpdateModal, setStatusUpdateModal] = useState(
    { open: false, id: null, status: null }
  );

  // fetch song-list API data
  const [loading, error, response] = useFetchData(`/song/all?keyword=${query.search}&limit=${query.rows}&page=${query.page}&sort=${query.sort}`, fetchAgain);

  // reset query options
  useEffect(() => {
    setQuery((prevState) => ({ ...prevState, page: '1' }));
  }, [query.rows, query.search]);

  useEffect(() => {
    refetch && setFetchAgain((prev) => !prev);
  }, [refetch]);

  return (
    <div>
      {/* song list ― query section */}
      <QueryOptions query={query} setQuery={setQuery} />

      {/* song list ― content section */}
      <div className='w-full flex flex-row flex-wrap items-center justify-center gap-2'>
        {error ? (
          <Result
            title='Failed to fetch'
            subTitle={error}
            status='error'
          />
        ) : (
          <Skeleton loading={loading} paragraph={{ rows: 10 }} active>
            {response?.data?.rows?.length === 0 ? (
              <Empty
                className='mt-10'
                description={(<span>Sorry! No data found.</span>)}
              />
            ) : (
              <div className='table-layout'>
                <div className='table-layout-container'>
                  <table className='data-table'>
                    {/* data table ― head */}
                    <thead className='data-table-head'>
                      <tr className='data-table-head-tr'>
                        <th className='data-table-head-tr-th' scope='col'>
                          Title
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Artist
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Album
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Length
                        </th>
                        <th className='data-table-head-tr-th' scope='col'>
                          Created At
                        </th>
                        {/* <th className='data-table-head-tr-th text-center' scope='col'>
                          Actions
                        </th> */}
                      </tr>
                    </thead>

                    {/* data table ― body */}
                    <tbody>
                      {response?.data?.rows?.map((data) => (
                        <tr className='data-table-body-tr' key={uniqueId()}>
                          <td className='data-table-body-tr-td'>
                            <a href={data?.audioUrl} target='_blank' rel='noopener noreferrer'>{data?.title}</a>
                          </td>
                          <td className='data-table-body-tr-td'>
                            {data?.album?.artist?.name}
                          </td>
                          <td className='data-table-body-tr-td'>
                            {data?.album?.name}
                          </td>
                          <td className='data-table-body-tr-td'>
                            {formatSeconds(data?.duration)}
                          </td>
                          <td className='data-table-body-tr-td'>
                            {formatDate(data?.createdAt)}
                          </td>
                          {/* <td className='data-table-body-tr-td !px-0 text-center'>
                            <Button
                              className='inline-flex items-center !px-2'
                              type='primary'
                              onClick={() => setStatusUpdateModal((prevState) => ({
                                ...prevState, open: true, id: data?.id, status: data?.status // Adaptez selon votre logique
                              }))}
                            >
                              Update Status
                            </Button>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Skeleton>
        )}
      </div>

      {/* song list ― pagination */}
      {response?.data?.total_page > 1 && (
      <Pagination
        className='my-5'
        onChange={(e) => setQuery((prevState) => ({ ...prevState, page: e }))}
        total={response?.data?.total_page * 10}
        current={response?.data?.current_page}
      />
      )}

      {/* song status update modal component */}
      {statusUpdateModal?.open && (
      <SongModal
        statusUpdateModal={statusUpdateModal}
        setStatusUpdateModal={setStatusUpdateModal}
        setFetchAgain={setFetchAgain}
      />
      )}
    </div>
  );
}

export default React.memo(SongsList);
