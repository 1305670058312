import React from 'react';
import Background from '../assets/images/fond.jpeg';
import GPL from '../assets/images/google-play-badge.png';

function Radio() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#212121',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        margin: 0,
        height: '100vh'
      }}
    >
      <div
        style={{
          marginTop: '10%',
          maxWidth: '90%',
          width: '600px',
          height: 'auto',
          backgroundColor: 'rgb(35, 35, 35)',
          padding: '20px',
          borderRadius: '12px'
        }}
      >
        <iframe
          src='https://a6.asurahosting.com/public/radio_emia/embed?theme=dark'
          frameBorder='0'
          style={{
            width: '100%',
            minHeight: '310px',
            border: 0
          }}
          title='Radio EMIA'
        />
        <a href='https://play.google.com/store/apps/details?id=com.radioemia.radio_v_01' target='_blank' rel='noopener noreferrer'>
          <img
            src={GPL}
            alt='Télécharger sur Google Play'
            style={{
              maxWidth: '30%',
              minWidth: '200px'
            }}
          />
        </a>
      </div>
    </div>
  );
}

export default Radio;
