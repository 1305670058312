/**
 * @name Radio EMIA
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
import {
  Button, Descriptions, Image, Result, Skeleton, Tag
} from 'antd';
import React, { useState } from 'react';
import useFetchData from '../../hooks/useFetchData';
import { getSessionUser } from '../../utils/authentication';
import { roleAsResponse, userSubscribedAsResponse } from '../../utils/responseAsStatus';
import ProfileEditModal from '../shared/ProfileEditModal';

function MyProfile() {
  const user = getSessionUser();
  const [editProfileModal, setEditProfileModal] = useState(false);

  // fetch user profile API data
  const [loading, error, response] = useFetchData(`/user_by_id/${user.id}`);

  return (
    <>
      <Skeleton loading={loading} paragraph={{ rows: 10 }} active avatar>
        {error ? (
          <Result
            title='Failed to fetch'
            subTitle={error}
            status='error'
          />
        ) : (
          <Descriptions
            title='My Information'
            bordered
            extra={(
              <Button
                onClick={() => setEditProfileModal(true)}
                shape='default'
                type='primary'
                size='middle'
              >
                Edit Profile
              </Button>
            )}
          >
            <Descriptions.Item label='Avatar' span={3}>
              {response?.data?.avatar ? (
                <Image
                  className='!w-[100px] !h-[100px]'
                  src={response?.data?.avatar}
                  crossOrigin='anonymous'
                  alt='user-image'
                />
              ) : 'N/A'}

            </Descriptions.Item>

            <Descriptions.Item label='Numéro'>
              {response?.data?.number}
            </Descriptions.Item>
            <Descriptions.Item label='Prénom' span={2}>
              {response?.data?.firstname}
            </Descriptions.Item>
            <Descriptions.Item label='Quartier'>
              {response?.data?.quartier}
            </Descriptions.Item>
            <Descriptions.Item label='Date de registe' span={2}>
              {response?.data?.registrationDate}
            </Descriptions.Item>

            <Descriptions.Item label='En ligne'>
              <Tag
                className='w-[80px] text-center uppercase'
                color={response?.data?.isOnline ? 'magenta' : 'purple'}
              >
                {response?.data?.isOnline ? 'Actif' : 'Inactif' }
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Status d`abonnement' span={2}>
              <Tag
                className='w-[100px] text-center uppercase'
                color={userSubscribedAsResponse(response?.data?.isSubscribed).color}
              >
                {userSubscribedAsResponse(response?.data?.isSubscribed).level}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Première inscription'>
              <Tag
                className='w-[50px] text-center uppercase'
                color={response?.data?.isFirstSubscription ? 'success' : 'error'}
              >
                {response?.data?.isFirstSubscription ? 'Yes' : 'No'}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Date de fin d`abonnement' span={2}>
              {response?.data?.expirationDate?.split('T')[0] || 'N/A'}
            </Descriptions.Item>

            <Descriptions.Item label='Date d`abonnement'>
              {response?.data?.payDate?.split('T')[0]}
            </Descriptions.Item>
            <Descriptions.Item label='Date d`inscription' span={2}>
              {response?.data?.createdAt?.split('T')[0]}
            </Descriptions.Item>

            <Descriptions.Item label='Role' span={3}>
              <Tag
                className='w-[100px] text-center uppercase'
                color={roleAsResponse(response?.data?.role).color}
              >
                {roleAsResponse(response?.data?.role).level}
              </Tag>
            </Descriptions.Item>

            <Descriptions.Item label='Appareil' span={3}>
              {response?.data?.deviceToken}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Skeleton>

      {/* profile edit modal component */}
      {editProfileModal && (
        <ProfileEditModal
          editProfileModal={editProfileModal}
          setEditProfileModal={setEditProfileModal}
          user={user}
        />
      )}
    </>
  );
}

export default React.memo(MyProfile);
