/**
 * @name Radio EMIA
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    timeZone: '',
    isLoading: false,
    reFetch: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    reFetchData: (state) => {
      state.reFetch = !state.reFetch;
    }
  }
});

export const { setLoading, reFetchData } = appSlice.actions;

export default appSlice.reducer;
