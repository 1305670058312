/**
 * @name Radio EMIA
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/middleware/PrivateRoute';
import PublicRoute from './components/middleware/PublicRoute';
import NotFound from './pages/Error';
import Home from './pages/Home';
import Login from './pages/Login';
import Main from './pages/Main';
import Radio from './pages/Radio';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Radio />} />

        {/* HOME ROUTE */}
        <Route path='/admin' element={<Home />} />

        {/* DASHBOARD ROUTE */}
        <Route path='/admin/main/:tab' element={(<PrivateRoute><Main /></PrivateRoute>)} />

        {/* LOGIN ROUTE */}
        <Route path='/admin/auth/login' element={(<PublicRoute><Login /></PublicRoute>)} />

        {/* ERROR ROUTE */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
