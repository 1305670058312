import React from 'react';
import {
  Button, Descriptions, Modal, Skeleton, Tag, Result
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import useFetchData from '../../hooks/useFetchData';
import notificationWithIcon from '../../utils/notification';
import ApiService from '../../utils/apiService';
import { reFetchData } from '../../store/slice/appSlice';

const { confirm } = Modal;

function RssDetails({ id }) {
  // fetch RSS feed details API data
  const [loading, error, response] = useFetchData(`/rss/${id}`);
  const dispatch = useDispatch();

  const handleDeleteRss = (_id) => {
    confirm({
      title: 'DELETE RSS FEED',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to delete this RSS feed permanently?',
      onOk() {
        return new Promise((resolve, reject) => {
          ApiService.delete(`/rss/${_id}`)
            .then((res) => {
              if (res?.result_code === 0) {
                notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'RSS feed deleted successfully');
                dispatch(reFetchData());
                resolve();
              } else {
                notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
                reject();
              }
            })
            .catch((err) => {
              notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || err?.response?.data?.result?.error || 'Sorry! Something went wrong. App server error');
              reject();
            });
        }).catch(() => notificationWithIcon('error', 'ERROR', 'Oops errors!'));
      }
    });
  };

  return (
    <Skeleton loading={loading} paragraph={{ rows: 10 }} active avatar>
      {error ? (
        <Result title='Failed to fetch' subTitle={error} status='error' />
      ) : (
        <Descriptions
          title='RSS Feed Information'
          bordered
          extra={(
            <Button onClick={() => handleDeleteRss(response?.data?.id)} type='default' danger>
              Delete Rss
            </Button>
          )}
        >
          <Descriptions.Item label='Title'>{response?.data?.title}</Descriptions.Item>
          <Descriptions.Item label='URL'>
            <a href={response?.data?.url} target='_blank' rel='noopener noreferrer'>
              {response?.data?.url}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label='Type'>
            <Tag className='text-center uppercase' color={response?.data?.type === 'song' ? 'blue' : 'yellow'}>{response?.data?.type}</Tag>
          </Descriptions.Item>
        </Descriptions>
      )}
    </Skeleton>
  );
}

export default React.memo(RssDetails);
