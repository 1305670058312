import {
  Button,
  Descriptions, Image, Result, Skeleton
} from 'antd';
import React, { useState, useEffect } from 'react';
import { v4 as uniqueId } from 'uuid';
import useFetchData from '../../hooks/useFetchData';
import ImageModal from '../shared/ImageModal';

function HomeImage() {
  const [fetchAgainHome, setFetchAgainHome] = useState(false);
  const [fetchAgainPlay, setFetchAgainPlay] = useState(false);

  const [loadingHome, errorHome, responseHome] = useFetchData('/image/home/get/one', fetchAgainHome);
  const [loadingPlay, errorPlay, responsePlay] = useFetchData('/image/play/get/one', fetchAgainPlay);

  const [imageModalHome, setImageModalHome] = useState({ open: false, id: null, status: null });
  const [imageModalPlay, setImageModalPlay] = useState({ open: false, id: null, status: null });

  useEffect(() => {
    if (fetchAgainHome) {
      setFetchAgainHome(false);
    }
  }, [fetchAgainHome]);

  useEffect(() => {
    if (fetchAgainPlay) {
      setFetchAgainPlay(false);
    }
  }, [fetchAgainPlay]);

  return (
    <Skeleton loading={loadingHome || loadingPlay} paragraph={{ rows: 10 }} active avatar>
      {errorHome ? (
        <Result
          title='Failed to fetch'
          subTitle={errorHome || errorPlay}
          status='error'
        />
      ) : (
        <Descriptions
          title='Information affiche dans le mobile'
          bordered
        >
          <Descriptions.Item label='Image d`accueil' span={3}>
            <Image.PreviewGroup>
              <Image
                key={uniqueId()}
                className='p-2'
                src={responseHome?.data?.imageUrl}
                crossOrigin='anonymous'
                alt='home-image'
                width='auto'
                height={150}
              />
            </Image.PreviewGroup>
            <Button
              className='inline-flex items-center ml-5 !px-2'
              type='primary'
              onClick={() => setImageModalHome((prevState) => ({
                ...prevState, open: true
              }))}
            >
              Update
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label='Image du Player' span={3}>
            <Image.PreviewGroup>
              <Image
                key={uniqueId()}
                className='p-2'
                src={responsePlay?.data?.imageUrl}
                crossOrigin='anonymous'
                alt='player-image'
                width='auto'
                height={150}
              />
            </Image.PreviewGroup>
            <Button
              className='inline-flex items-center ml-5 !px-2'
              type='primary'
              onClick={() => setImageModalPlay((prevState) => ({
                ...prevState, open: true
              }))}
            >
              Update
            </Button>
          </Descriptions.Item>
        </Descriptions>
      )}
      {imageModalHome?.open && (
        <ImageModal
          statusUpdateModal={imageModalHome}
          setStatusUpdateModal={setImageModalHome}
          setFetchAgain={setFetchAgainHome}
          uploadUrl='/image/home/upload'
        />
      )}
      {imageModalPlay?.open && (
        <ImageModal
          statusUpdateModal={imageModalPlay}
          setStatusUpdateModal={setImageModalPlay}
          setFetchAgain={setFetchAgainPlay}
          uploadUrl='/image/play/upload'
        />
      )}
    </Skeleton>
  );
}

export default React.memo(HomeImage);
