/* eslint-disable no-underscore-dangle */
import {
  Button, Input, Modal, Upload, Form
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function ArtistModal({ statusUpdateModal, setStatusUpdateModal, setFetchAgain }) {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (statusUpdateModal?.artist) {
      form.setFieldsValue({ name: statusUpdateModal.artist.name });
      if (statusUpdateModal.artist.image) {
        setFileList([
          {
            uid: '-1',
            name: 'artist_image',
            url: statusUpdateModal.artist.image
          }
        ]);
      } else {
        setFileList([]);
      }
    } else {
      form.resetFields();
      setFileList([]);
    }
  }, [statusUpdateModal, form]);

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList.slice(-1)); // Garde uniquement la dernière image ajoutée
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      const artistFormData = new FormData();
      artistFormData.append('name', values.name);

      if (fileList.length > 0 && fileList[0].originFileObj) {
        artistFormData.append('image', fileList[0].originFileObj);
      }

      const apiCall = statusUpdateModal?.artist ?
        ApiService.put(`/artist/${statusUpdateModal.artist.id}`, artistFormData) :
        ApiService.post('/artist/add', artistFormData);

      apiCall
        .then((res) => {
          setLoading(false);
          if (res?.result_code === 0) {
            notificationWithIcon('success', 'SUCCESS', res?.result?.message || 'Operation successful');
            setStatusUpdateModal({ open: false, artist: null });
            setFetchAgain((prevState) => !prevState);
          } else {
            notificationWithIcon('error', 'ERROR', 'Sorry! Something went wrong. App server error');
          }
        })
        .catch((err) => {
          setLoading(false);
          notificationWithIcon('error', 'ERROR', err?.response?.data?.result?.error?.message || 'Sorry! Something went wrong. App server error');
        });
    } catch (errorInfo) {
      console.log('Validation Failed:', errorInfo);
    }
  };

  return (
    <Modal
      title={statusUpdateModal?.artist ? 'Update Artist Info' : 'Create Artist'}
      open={statusUpdateModal?.open}
      onCancel={() => setStatusUpdateModal({ open: false, artist: null })}
      footer={[
        <Button key='back' onClick={() => setStatusUpdateModal({ open: false, artist: null })}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' onClick={handleSubmit} disabled={loading} loading={loading}>
          {statusUpdateModal?.artist ? 'Update' : 'Create'}
        </Button>
      ]}
    >
      <Form form={form} layout='vertical'>
        <Form.Item
          label='Artist Name'
          name='name'
          rules={[{ required: true, message: 'Please enter the artist name' }]}
        >
          <Input placeholder='Enter artist name' />
        </Form.Item>

        <Form.Item label='Upload Image'>
          <Upload
            listType='picture-card'
            onChange={handleUploadChange}
            accept='.jpg,.jpeg,.png'
            beforeUpload={() => false}
            fileList={fileList}
            maxCount={1} // Une seule image autorisée
          >
            {fileList.length >= 1 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ArtistModal;
