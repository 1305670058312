/**
 * @name Radio EMIA
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
import { Card, Statistic } from 'antd';
import React from 'react';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';

const formatter = (value) => <CountUp end={value} separator=',' />;
const gridStyle = { width: '50%', textAlign: 'center' };

function AudioCard({ loading, data }) {
  const navigate = useNavigate();

  return (
    <Card
      className='w-full cursor-pointer md:w-[49.5%]'
      onClick={() => navigate('/admin/main/hotel-rooms')}
      title='Audios Information'
      loading={loading}
    >
      <Card.Grid style={gridStyle}>
        <Statistic
          className='whitespace-normal lg:whitespace-nowrap'
          title='Total Audio'
          formatter={formatter}
          value={data?.totalAudios || 0}
        />
      </Card.Grid>

      <Card.Grid style={gridStyle}>
        <Statistic
          className='whitespace-normal lg:whitespace-nowrap'
          title='New Audio (-7days)'
          formatter={formatter}
          value={data?.totalNewAudios || 0}
        />
      </Card.Grid>

      <Card.Grid style={gridStyle}>
        <Statistic
          className='whitespace-normal lg:whitespace-nowrap'
          title='Podcast Audio'
          formatter={formatter}
          value={data?.podcast || 0}
        />
      </Card.Grid>

      <Card.Grid style={gridStyle}>
        <Statistic
          className='whitespace-normal lg:whitespace-nowrap'
          title='Song Audio'
          formatter={formatter}
          value={data?.song || 0}
        />
      </Card.Grid>
    </Card>
  );
}

export default AudioCard;
