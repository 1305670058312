/**
 * @name Song Management
 * @author Mr. Josia Yvan (M1 MSI_ESTI)
 * @description System API and Management System Software ~ Developed By Mr. Josia Yvan (M1 MSI_ESTI)
 * @copyright ©2023 ― Mr. Josia Yvan (M1 MSI_ESTI). All rights reserved.
 * @version v0.0.1
 *
 */
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import React, { useState } from 'react';
import SongList from '../song/SongList';
import ApiService from '../../utils/apiService';
import notificationWithIcon from '../../utils/notification';

function Songs() {
  const [load, setLoad] = useState(false);
  const [fetch, setFetch] = useState(false);

  const defaultPanes = new Array(1).fill(null).map((_, index) => ({
    key: String(index + 1),
    label: 'Songs List',
    children: <SongList refetch={fetch} />,
    closable: false
  }));

  const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
  const [items] = useState(defaultPanes);

  const refresh = async () => {
    setLoad(true);
    try {
      const res = await ApiService.get('/rss/load_db');
      if (res.albums) {
        setFetch((prev) => !prev);
      }
    } catch (error) {
      notificationWithIcon('ERROR', 'Please try again!');
    } finally {
      setLoad(false);
    }
  };

  return (
    <Tabs
      onChange={(key) => setActiveKey(key)}
      tabBarExtraContent={(
        <Button className='inline-flex items-center' icon={load ? <LoadingOutlined /> : <RedoOutlined />} onClick={refresh} type='primary' size='large' disabled={load}>
          Refresh from rss
        </Button>
      )}
      activeKey={activeKey}
      type='editable-card'
      items={items}
      size='large'
      hideAdd
    />
  );
}

export default React.memo(Songs);
