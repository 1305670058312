const dayjs = require('dayjs');

exports.formatNumber = (inputString) => {
  // Extraire chaque segment
  const part1 = inputString.substring(0, 3);
  const part2 = inputString.substring(3, 5);
  const part3 = inputString.substring(5, 8);
  const part4 = inputString.substring(8, 10);
  const part5 = inputString.substring(10, 20);

  const resultString = `${part1} ${part2} ${part3} ${part4} ${part5}`;

  return resultString;
};

exports.formatDate = (isoDate) => dayjs(isoDate).format('DD MMMM YYYY (HH:MM)');

exports.formatSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const result = [];
  if (hours > 0) result.push(`${hours}h`);
  if (minutes > 0) result.push(`${minutes}m`);
  if (remainingSeconds > 0 || result.length === 0) result.push(`${remainingSeconds}s`);

  return result.join(' ');
};
